import { IPagination } from "@/models/Pagination";
import {
  ITenantUser,
  ITenantUserUpdate,
  IPasswordUser,
  ITenantProfileDTO,
  ITenantRegistration,
  ICreateTenantRequest,
  ITenantParameters,
  ITenantUpdateStatusRequest,
  ITenantPartnerParameters,
  ITenantPartner,
  ITenantDTO,
  ITenantUpdateForm,
  ITenantUpdateInvitationStatusRequest,
} from "@/models/Tenant";
import { ITenantUserDTO } from "@/models/TenantUser";
import { IUserVerificationDTO } from "@/models/User";
import { ErrorResponse } from "@/services/axios/error";
import { TenantUserAPI } from "@/services/tenantUser";
import { UserAPI } from "@/services/user";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { IResponse } from "@/services/axios/entities";
import { getAccessToken, getTenantId } from "@/utils/storage";
import { DOCTYPE } from "@ems/constants/doc_type";
import CompanyAccountManagementModule from "../CompanyAccountManagement/CompanyAccountManagement.module";

const name = "TenantUserModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class TenantUserModule extends VuexModule {
  private dataGetListTenantUser = [] as ITenantUserDTO[];
  private dataGetTenantUser = {} as ITenantUserDTO;
  private detailTenantUser = {} as ITenantUpdateForm;

  private pagination = {} as IPagination;
  private paginationBrandOwner = {} as IPagination;
  private paginationTierOne = {} as IPagination;
  private errorUpdateTenantStatus = {} as ErrorResponse;
  private updateTenantPasswordUser = {} as ITenantUpdateForm;
  private sendActivationEmailError = {} as ErrorResponse;
  private detailTenant = {} as ITenantDTO;
  private dataGetListTenant = [] as ITenantRegistration[];
  private dataGetListTenantPartner = [] as ITenantPartner[];
  private dataGetListTenantInvitor = [] as ITenantPartner[];

  private prefillData = {} as ITenantRegistration;

  private dataTenantProfile = {
    lat: 23.4999272,
    lng: 118.1482716,
  } as ITenantProfileDTO;
  private dataTenantRegistration = {} as ITenantRegistration;
  private dataUpdateTenantStatus = false;
  private dataUpdateInvitationStatus = false;

  private isLoadingListTenantUser = false;
  private isLoadingDataListTenant = false;
  private isLoadingDataTenant = false;
  private isLoadingCreateTenant = false;
  private isLoadingUpdateTenantStatus = false;
  private isLoadingPrefillData = false;
  private isLoadingUpdateInvitationStatus = false;
  private isLoadingDataGetListTenantPartner = false;
  private isLoadingDataGetListTenantInvitor = false;

  private errorPrefillData = {} as ErrorResponse;
  private errorUpdateTenantPasswordUser = {} as ErrorResponse;
  private errorUpdateUser = {} as ErrorResponse;
  private errorUpdatePassword = {} as ErrorResponse;
  private errorDataGetListTenantUser = {} as ErrorResponse;
  private errorCreateUser = {} as ErrorResponse;
  private errorVerificationUser = {} as ErrorResponse;
  private errorDetailTenant = {} as ErrorResponse;
  private errorTenantProfile = {} as ErrorResponse;
  private errorGetListTenant = {} as ErrorResponse;
  private errorDataTenantUser = {} as ErrorResponse;
  private errorUpdateInvitationStatus = {} as ErrorResponse;
  private errorGetGetListTenantPartner = {} as ErrorResponse;
  private errorGetGetListTenantInvitor = {} as ErrorResponse;

  get hasCreateUserErrorMsg() {
    return !isEmpty(this.errorCreateUser);
  }
  get errorVerificationUserGetter() {
    return this.errorVerificationUser;
  }

  get errorUpdateTenantStatusGetter() {
    return this.errorUpdateTenantStatus;
  }
  get isLoadingListTenantUserGetter() {
    return this.isLoadingListTenantUser;
  }

  get dataTenantUserGetter() {
    return this.dataGetTenantUser;
  }

  get dataListTenantUserGetter() {
    return this.dataGetListTenantUser;
  }
  get paginationGetter() {
    return this.pagination;
  }

  get paginationBrandOwnerGetter() {
    return this.paginationBrandOwner;
  }

  get paginationTierOneGetter() {
    return this.paginationTierOne;
  }

  get dataTenantProfileGetter() {
    return this.dataTenantProfile;
  }

  get dataTenantRegistrationGetter() {
    return this.dataTenantRegistration;
  }

  get dataGetListTenantGetter() {
    return this.dataGetListTenant;
  }

  get dataGetListTenantPartnerGetter() {
    return this.dataGetListTenantPartner;
  }

  get dataGetListTenantInvitorGetter() {
    return this.dataGetListTenantInvitor;
  }

  get dataUpdateTenantStatusGetter() {
    return this.dataUpdateTenantStatus;
  }

  get dataUpdateInvitationStatusGetter() {
    return this.dataUpdateInvitationStatus;
  }

  get isLoadingTenantDataGetter() {
    return this.isLoadingDataTenant;
  }

  get isLoadingDataListTenantGetter() {
    return this.isLoadingDataListTenant;
  }

  get isLoadingCreateTenantGetter() {
    return this.isLoadingCreateTenant;
  }

  get isLoadingUpdateTenantStatusGetter() {
    return this.isLoadingUpdateTenantStatus;
  }

  get prefillDataGetter() {
    return this.prefillData;
  }

  get isLoadingPrefillDataGetter() {
    return this.isLoadingPrefillData;
  }

  get isLoadingUpdateInvitationStatusGetter() {
    return this.isLoadingUpdateInvitationStatus;
  }

  @Mutation
  setLoadingGetTenantRegistration(loading: boolean) {
    this.isLoadingDataTenant = loading;
  }

  @Mutation
  getTenantRegistrationSuccess(data: ITenantRegistration) {
    this.dataTenantRegistration = { ...data };
    this.isLoadingDataTenant = false;
  }
  @Mutation
  getTenantRegistrationFailure(error: ErrorResponse) {
    ElMessage.error("Get Tenant Account Registration fail.");
    this.errorTenantProfile = error;
    this.isLoadingDataTenant = false;
  }

  @Action({ rawError: true })
  async getTenantAccountRegistrationAction(tenantId?: string) {
    this.setLoadingGetTenantRegistration(true);

    const servicesTenantUserAPI = new TenantUserAPI();
    const { data, error } = await servicesTenantUserAPI.getTenant(
      tenantId ?? ""
    );
    if (error) {
      this.getTenantRegistrationFailure(error);
    } else {
      this.getTenantRegistrationSuccess(data);
    }
  }

  @Mutation
  createUserSuccess() {
    ElMessage.success("Successfully added user!");
    this.errorCreateUser = {} as ErrorResponse;
  }
  @Mutation
  createUserFailure(error: ErrorResponse) {
    ElMessage.error("Create new User fail.");
    this.errorCreateUser = error;
  }
  @Action({ rawError: true })
  async createUser(payload: ITenantUser) {
    const servicesTenantUserAPI = new TenantUserAPI();
    const { error } = await servicesTenantUserAPI.createUser(payload);

    if (error) {
      this.createUserFailure(error);
    } else {
      this.createUserSuccess();
    }
  }

  @Mutation
  setLoadingCreateTenant(loading: boolean) {
    this.isLoadingCreateTenant = loading;
  }

  @Mutation
  createTenantSuccess() {
    ElMessage.success("Successfully added Tenant!");
    this.errorCreateUser = {} as ErrorResponse;
    this.isLoadingCreateTenant = false;
  }
  @Mutation
  createTenantFailure(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorCreateUser = error;
    this.isLoadingCreateTenant = false;
  }

  @Action({ rawError: true })
  async createTenantUser(payload: ITenantRegistration) {
    const createTenantRequest: ICreateTenantRequest = {
      ...payload,
    };
    const accessToken = getAccessToken();
    const servicesTenantUserAPI = new TenantUserAPI(accessToken ?? "");
    const { data, error } = await servicesTenantUserAPI.createTenant(
      createTenantRequest
    );

    if (error) {
      this.createTenantFailure(error);
      return null;
    } else {
      if (payload.CompanyRegistrationDocument) {
        const formData = new FormData();
        formData.append("File", payload.CompanyRegistrationDocument[0].raw);
        formData.append(
          "DocType",
          DOCTYPE.CompanyRegistrationDocument.toString()
        );
        await CompanyAccountManagementModule.pushFile({
          Id: data.TenantId,
          File: formData,
        });
      }
      if (payload.LetterOfAuthorization) {
        const formData = new FormData();
        formData.append("File", payload.LetterOfAuthorization[0].raw);
        formData.append("DocType", DOCTYPE.LetterOfAuthorization.toString());
        await CompanyAccountManagementModule.pushFile({
          Id: data.TenantId,
          File: formData,
        });
      }
      if (payload.OtherRelevantDocuments) {
        const listFiles = [] as any;

        payload.OtherRelevantDocuments.map(async (file: any) => {
          const formData = new FormData();
          formData.append("File", file.raw);
          formData.append("DocType", DOCTYPE.OtherRelevantDocuments.toString());
          listFiles.push(
            CompanyAccountManagementModule.pushFile({
              Id: data.TenantId,
              File: formData,
            })
          );
        });
        await Promise.all(listFiles);
        this.setLoadingCreateTenant(true);
        this.createTenantSuccess();
      }
      return data;
    }
  }

  @Mutation
  setLoadingPrefillData(loading: boolean) {
    this.isLoadingPrefillData = loading;
  }

  @Mutation
  getPrefillDataSuccess(data: ITenantRegistration) {
    this.prefillData = data;
    this.isLoadingPrefillData = false;
  }

  @Mutation
  getPrefillDataFailure(error: ErrorResponse) {
    ElMessage.error("Failed to load prefill data.");
    this.errorPrefillData = error;
    this.isLoadingPrefillData = false;
  }

  @Action({ rawError: true })
  async getPrefillDataAction() {
    this.setLoadingPrefillData(true);
    const servicesTenantUserAPI = new TenantUserAPI();
    const { data, error } =
      await servicesTenantUserAPI.getAccountRegistrationPrefillData();
    if (error) {
      this.getPrefillDataFailure(error);
    } else {
      this.getPrefillDataSuccess(data);
    }
  }

  @Mutation
  setLoadingListTenantUser(loading: boolean) {
    this.dataGetListTenantUser = [] as ITenantUserDTO[];
    this.isLoadingListTenantUser = loading;
  }
  @Mutation
  getListTenantUserSuccess(data: any) {
    this.errorDataGetListTenantUser = {} as ErrorResponse;
    const { Collection, ...rest } = data;
    this.dataGetListTenantUser = Collection;
    this.pagination = rest;
    this.isLoadingListTenantUser = false;
  }
  @Mutation
  getListTenantUserFailure(error: ErrorResponse) {
    this.errorDataGetListTenantUser = error;
    this.isLoadingListTenantUser = false;
  }
  @Action({ rawError: true })
  async getListTenantUser(payload: any) {
    this.setLoadingListTenantUser(true);
    const servicesTenantUserAPI = new TenantUserAPI();
    const { data, error } = await servicesTenantUserAPI.getListTenantUser(
      payload.params
    );
    if (error) {
      this.getListTenantUserFailure(error);
    } else {
      this.getListTenantUserSuccess(data);
    }
  }

  @Mutation
  updateUserSuccess(data: any) {
    ElMessage.success("Update User success.");
    this.errorUpdateUser = {} as ErrorResponse;
    this.detailTenantUser = data;
  }
  @Mutation
  updateUserFailure(error: ErrorResponse) {
    this.errorUpdateUser = error;
  }
  @Action({ rawError: true })
  async updateUser(payload: ITenantUserUpdate) {
    const servicesTenantUserAPI = new TenantUserAPI();
    const { data, error } = await servicesTenantUserAPI.updateUser(
      payload.UserId,
      payload
    );

    if (error) {
      this.updateUserFailure(error);
    } else {
      await this.getTenantUser({
        UserId: payload.UserId,
      });
      this.updateUserSuccess(data);
    }
  }

  @Mutation
  updatePasswordUserSuccess() {
    ElMessage.success("Update Password success.");
    this.errorUpdatePassword = {} as ErrorResponse;
  }
  @Mutation
  updatePasswordUserFailure(error: ErrorResponse) {
    this.errorUpdatePassword = error;
  }
  @Action({ rawError: true })
  async updatePasswordUser(payload: IPasswordUser) {
    const servicesTenantUserAPI = new TenantUserAPI();
    const { error } = await servicesTenantUserAPI.updatePassword(
      payload.UserId,
      payload
    );

    if (error) {
      this.updatePasswordUserFailure(error);
    } else {
      this.updatePasswordUserSuccess();
    }
  }

  @Mutation
  loadingTenantUser() {
    this.dataGetTenantUser = {} as ITenantUserDTO;
  }
  @Mutation
  getTenantUserSuccess(data: ITenantUserDTO) {
    this.errorDataGetListTenantUser = {} as ErrorResponse;
    this.dataGetTenantUser = data;
  }
  @Mutation
  getTenantUserFailure(error: ErrorResponse) {
    this.errorDataTenantUser = error;
  }

  @Mutation
  resetTenantUserEmpty() {
    this.dataGetTenantUser = {} as ITenantUserDTO;
  }
  @Action({ rawError: true })
  async getTenantUser(payload: { UserId: string }): Promise<IResponse> {
    const { UserId } = payload;
    this.loadingTenantUser();
    const servicesTenantUserAPI = new TenantUserAPI();
    const { data, error } = await servicesTenantUserAPI.getTenantUser(UserId);

    if (error) {
      this.getTenantUserFailure(error);
      return { error };
    } else {
      this.getTenantUserSuccess(data);
      return { data };
    }
  }

  @Mutation
  sendActivationEmailFailure(error: ErrorResponse) {
    this.sendActivationEmailError = error;
  }
  @Action({ rawError: true })
  async sendActivationEmail(payload: { UserId: string }) {
    const { UserId } = payload;
    this.loadingTenantUser();
    const servicesTenantUserAPI = new TenantUserAPI();
    const { error } = await servicesTenantUserAPI.sendActivationEmail(UserId);
    if (error) {
      this.sendActivationEmailFailure(error);
      return { error };
    }
    this.getListTenantUser({});
  }

  @Mutation
  verificationUserSuccess() {
    this.errorVerificationUser = {} as ErrorResponse;
  }
  @Mutation
  verificationUserFailure(error: ErrorResponse) {
    this.errorVerificationUser = error.message;
  }
  @Action({ rawError: true })
  async verificationUser(payload: IUserVerificationDTO) {
    const servicesUserAPI = new UserAPI();
    const { error } = await servicesUserAPI.userVerification(payload);

    if (error) {
      this.verificationUserFailure(error);
      return error.message;
    } else {
      this.verificationUserSuccess();
      return "";
    }
  }

  @Mutation
  getTenantProfileSuccess(data: ITenantProfileDTO) {
    if (data.lat && data.lng) {
      this.dataTenantProfile = data;
    } else {
      this.dataTenantProfile.Name = data.Name;
    }
  }
  @Mutation
  getTenantProfileFailure(error: ErrorResponse) {
    this.errorTenantProfile = error;
  }

  @Action({ rawError: true })
  async getTenantProfile() {
    const servicesTenantUserAPI = new TenantUserAPI();
    const { data, error } = await servicesTenantUserAPI.getTenantProfile();

    if (error) {
      this.getTenantProfileFailure(error);
    } else {
      this.getTenantProfileSuccess(data);
    }
  }

  @Mutation
  setLoadingDataListTenant(loading: boolean) {
    this.dataGetListTenant = [] as ITenantRegistration[];
    this.isLoadingDataListTenant = loading;
  }
  @Mutation
  setLoadingDataListTenantPartner(loading: boolean) {
    this.dataGetListTenant = [] as ITenantRegistration[];
    this.isLoadingDataGetListTenantPartner = loading;
  }
  @Mutation
  setLoadingDataListTenantInvitor(loading: boolean) {
    this.dataGetListTenant = [] as ITenantRegistration[];
    this.isLoadingDataGetListTenantInvitor = loading;
  }
  @Mutation
  getListTenantSuccess(data: any) {
    this.errorGetListTenant = {} as ErrorResponse;
    const { Collection, ...rest } = data;
    this.dataGetListTenant = Collection;
    this.pagination = rest;
    this.isLoadingDataListTenant = false;
  }

  @Mutation
  getListTenantInvitorSuccess(data: any) {
    this.errorGetListTenant = {} as ErrorResponse;
    const { Collection, ...rest } = data;
    this.dataGetListTenantInvitor = Collection;
    this.paginationBrandOwner = rest;
    this.isLoadingDataGetListTenantInvitor = false;
  }

  @Mutation
  getListTenantInvitorFailure(error: ErrorResponse) {
    this.errorGetGetListTenantInvitor = error;
    this.isLoadingDataGetListTenantInvitor = false;
  }

  @Mutation
  getListTenantPartnerSuccess(data: any) {
    this.errorGetListTenant = {} as ErrorResponse;
    const { Collection, ...rest } = data;
    this.dataGetListTenantPartner = Collection;
    this.paginationTierOne = rest;
    this.isLoadingDataGetListTenantPartner = false;
  }

  @Mutation
  getListTenantPartnerFailure(error: ErrorResponse) {
    this.errorGetGetListTenantPartner = error;
    this.isLoadingDataGetListTenantPartner = false;
  }

  @Mutation
  getListTenantFailure(error: ErrorResponse) {
    this.errorGetListTenant = error;
    this.isLoadingDataListTenant = false;
  }

  @Action({ rawError: true })
  async getListTenantAction(params?: ITenantParameters) {
    this.setLoadingDataListTenant(true);

    const servicesTenantAPI = new TenantUserAPI();
    const { data, error } = await servicesTenantAPI.getListTenant(params);

    if (error) {
      this.getListTenantFailure(error);
    } else {
      this.getListTenantSuccess(data);
    }
  }

  @Action({ rawError: true })
  async getListTenantPartnerAction(params?: ITenantPartnerParameters) {
    params?.IsGetBrandOwners
      ? this.setLoadingDataListTenantInvitor(true)
      : this.setLoadingDataListTenantPartner(true);

    const servicesTenantAPI = new TenantUserAPI();
    const { data, error } = await servicesTenantAPI.getListTenantPartner(
      params
    );

    if (error) {
      params?.IsGetBrandOwners
        ? this.getListTenantInvitorFailure
        : this.getListTenantPartnerFailure(error);
    } else {
      params?.IsGetBrandOwners
        ? this.getListTenantInvitorSuccess(data)
        : this.getListTenantPartnerSuccess(data);
    }
  }

  @Mutation
  setLoadingUpdateTenantStatus(loading: boolean) {
    this.isLoadingUpdateTenantStatus = loading;
  }
  @Mutation
  updateTenantStatusSuccess(data: any) {
    this.dataUpdateTenantStatus = data;
    this.isLoadingUpdateTenantStatus = false;
  }
  @Mutation
  updateTenantStatusFailure(error: ErrorResponse) {
    this.isLoadingUpdateTenantStatus = false;
    this.errorUpdateTenantStatus = error;
  }
  @Action({ rawError: true })
  async updateTenantStatus(params: ITenantUpdateStatusRequest) {
    this.setLoadingUpdateTenantStatus(true);

    const servicesTenantAPI = new TenantUserAPI();
    const { data, error } = await servicesTenantAPI.updateTenantStatus(params);

    if (error) {
      this.updateTenantStatusFailure(error);
    } else {
      this.updateTenantStatusSuccess(data);
    }
  }

  @Mutation
  setLoadingUpdateInvitationStatus(loading: boolean) {
    this.isLoadingUpdateInvitationStatus = loading;
  }

  @Mutation
  updateInvitationStatusSuccess(data: any) {
    this.dataUpdateInvitationStatus = data;
    this.isLoadingUpdateInvitationStatus = false;
  }

  @Mutation
  updateInvitationStatusFailure(error: ErrorResponse) {
    ElMessage.error("Failed to update invitation status.");
    this.errorUpdateInvitationStatus = error;
    this.isLoadingUpdateInvitationStatus = false;
  }

  @Action({ rawError: true })
  async updateTenantInvitationStatusAction(
    params: ITenantUpdateInvitationStatusRequest
  ) {
    this.setLoadingUpdateInvitationStatus(true);
    const servicesTenantUserAPI = new TenantUserAPI();
    const { data, error } =
      await servicesTenantUserAPI.updateTenantInvitationStatus(params);
    if (error) {
      this.updateInvitationStatusFailure(error);
    } else {
      this.updateInvitationStatusSuccess(data);
    }
  }
}
export default getModule(TenantUserModule);
